import { Link } from 'react-router-dom';
import { Button } from './Button';

export function AccessBanner() {
  return (
    <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-40 bg-canvass-800">
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-12">
        <p
          className="font-sofia font-extrabold leading-[54.4px] tracking-tighter text-white text-5xl xl:text-6xl uppercase"
          style={{ wordWrap: 'break-word' }}
        >
          REQUEST EARLY ACCESS NOW
        </p>
        <div>
          <Link to="/request-access"><Button type="fill-reverse">Request Access</Button></Link>
        </div>
      </div>
    </div>
  );
}
