import { Link } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { AccessBanner } from '../components/AccessBanner';
import { Button } from '../components/Button';

import downArrow from '../assets/down-arrow.svg';

const faqs = [
  {
    question: 'Is the app available for both Android and iOS devices?',
    answer:
      'Yes, the app is available for both Android and iOS devices. You can download it from the Google Play Store or the Apple App Store.',
  },
  {
    question: 'How do I download and install the app on my smartphone?',
    answer:
      `To download and install the app, simply go to your device's app store, search for "MyCanvass" and then click on the "Install" button.`,
  },
  {
    question: 'Do I need to create an account to use the app?',
    answer:
      'Yes, creating an account is required to access personalized voter information and to track your voter engagement. It helps us provide a better user experience.',
  },
  {
    question: 'Is my personal data safe and secure within the app?',
    answer:
      'Yes, we take data privacy and security seriously. Your personal information is encrypted and stored securely. We do not share your data with third parties without your consent.',
  },
  {
    question: 'What information do I need to provide during the registration process?',
    answer:
      `During registration, you'll need to provide your name, phone number, email address, and create a secure password. Optionally, you can add your address to receive location-specific voting details. You will also be required to complete a background check in order to be approved for certain campaigns.`,
  },
  {
    question: 'How frequently is the app updated with the latest voter information?',
    answer:
      'The app is regularly updated with the most current voter information and election data to ensure accuracy and relevance.',
  },
];

export function Faq() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 pt-40 pb-28 flex flex-col items-center gap-28">
        <div className="text-center flex flex-col items-center gap-7">
          <h1 className="text-h1 font-sofia uppercase text-center">
            Frequently asked <br />
            Questions
          </h1>
          <p className="max-w-xl text-xl">
            Find answers to all of the most common questions about how MyCanvass
            works. Don’t see what you’re looking for? Contact us through the
            link below.
          </p>
          <Link to="/contact">
            <Button type="fill">Contact</Button>
          </Link>
        </div>
      </div>

      <div className="mx-auto max-w-4xl pb-40 px-4">
        <dl className="space-y-2 lg:space-y-4 p-2 lg:p-4 bg-canvass-50 rounded-3xl">
          {faqs.map((faq) => (
            <Disclosure
              as="div"
              key={faq.question + Math.random()}
              className="rounded-3xl px-6 py-5 md:px-12 md:py-10 bg-white shadow-md"
            >
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                      <span className="text-base font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        <img
                          src={downArrow}
                          alt=""
                          className={`transition-all duration-150 ${
                            open && 'rotate-180'
                          }`}
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Transition
                    show={open}
                    enter="transition duration-150 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-100 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>

      <AccessBanner />
    </>
  );
}
