interface ButtonProps {
  children?: any;
  type: 'outline' | 'fill' | 'fill-reverse';
  onClick?: () => void;
}

export function Button(props: ButtonProps) {
  switch (props.type) {
    case 'outline':
      return (
        <button
          type="button"
          onClick={props.onClick}
          className="rounded-full bg-transparent px-5 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-canvass-900 outline outline-1 outline-canvass-500 transition-transform hover:scale-105 active:scale-100 duration-150"
        >
          {props.children}
        </button>
      );
    case 'fill':
      return (
        <button
          type="button"
          onClick={props.onClick}
          className="rounded-full bg-mc-gradient-1 px-5 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-canvass-900 text-canvass-50 font-extrabold transition-transform hover:scale-105 active:scale-100 duration-150"
        >
          {props.children}
        </button>
      );
    case 'fill-reverse':
      return (
        <button
          type="button"
          onClick={props.onClick}
          className="rounded-full bg-canvass-50 px-5 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-canvass-900 font-extrabold transition-transform hover:scale-105 active:scale-100 duration-150"
        >
          {props.children}
        </button>
      );
  }
}
