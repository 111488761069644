import { Link } from 'react-router-dom';
import { AccessBanner } from '../components/AccessBanner';
import { Button } from '../components/Button';
import halfCircle from '../assets/mc-q-circle.png';
import checkCircle from '../assets/check-circle.svg';

const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '/request-basic',
    price: 'TBD',
    description: 'The Basics to get the word out:',
    features: ['Volunteer Support', '2 Campaign Scripts', 'Walking and Calling'],
  },
  {
    name: 'Advanced',
    id: 'tier-advanced',
    href: '/request-advanced',
    price: 'TBD',
    description: 'Everything in Basic, plus:',
    features: ['Paid Canvasser Support', '+5 Campaign Scripts', 'Ballot Collection (where legal!)'],
  },
  {
    name: 'Premium',
    id: 'tier-premium',
    href: '/request-premium',
    price: 'TBD',
    description: 'Everything in Advanced, plus:',
    features: ['Collaboration Between Campaigns', 'Unlimited Campaign Scripts', 'Unlimited Volunteers and Paid Workers'],
  },
];

export function Pricing() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-40 flex flex-col items-center gap-28">
        <div className="text-center flex flex-col items-center">
          <h1 className="text-h1 font-sofia uppercase mb-7">
            <span className="text-canvass-500">Maximize</span> your <br />
            Campaign’s Potential
          </h1>
          <p className="max-w-xl text-xl">
            Give your campaign the tools it needs to become a grassroots
            movement with one of the following pricing plans.
          </p>
        </div>

        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-8 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
            {tiers.map((tier) => (
              <div key={tier.id} className="p-10 lg:p-10 shadow-md rounded-lg">
                <h3
                  id={tier.id}
                  className="text-3xl font-sofia leading-7 tracking-tight uppercase"
                >
                  {tier.name}
                </h3>
                <div className="flex flex-col gap-8">
                  <p className="mt-12 flex items-baseline gap-x-4">
                    <span className="text-h1small font-sofia">
                      {tier.price}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      per month
                    </span>
                  </p>
                 <Link to={tier.href}><Button type="fill">Get Started</Button></Link>
                  <div className="flex flex-col gap-7">
                    <div className="flex flex-col gap-1">
                      <p className="text-xl font-extrabold">Features</p>
                      <p className="leading-6">
                        {tier.description}
                      </p>
                    </div>
                    <ul className="space-y-3 leading-6">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <img
                            src={checkCircle}
                            alt="✓"
                            className="h-6 w-5 flex-none"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64">
        <img src={halfCircle} alt="" className="w-full" />
      </div>
      <AccessBanner />
    </>
  );
}
