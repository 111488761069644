import { useState, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from './Button';
import logo from '../assets/logo-long.svg';
import logoDark from '../assets/logo-long-dark.svg';

interface HeaderProps {
  surfaceDark?: boolean;
}

export function Header(props: HeaderProps) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className={`bg-transparent ${props.surfaceDark && 'text-canvass-50'}`}>
      <div className="py-7 px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 flex justify-between items-center">
        <Link to="/">
          <img
            src={props.surfaceDark ? logoDark : logo}
            className="h-6"
            alt="MyCanvass"
          />
        </Link>

        <nav className="hidden md:block py-2">
          <ul className="text-sm xl:text-base flex flex-row items-center gap-2 md:gap-4 lg:gap-8">
            <li
              className={`hover:text-shadow-sm ${
                props.surfaceDark ? 'shadow-white' : 'shadow-canvass-900'
              } transition-all duration-150`}
            >
              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  isActive
                    ? `text-shadow-sm  ${
                        props.surfaceDark
                          ? 'shadow-white text-white'
                          : 'shadow-canvass-900 text-canvass-900'
                      }`
                    : ''
                }
              >
                Pricing
              </NavLink>
            </li>
            <li
              className={`hover:text-shadow-sm ${
                props.surfaceDark ? 'shadow-white' : 'shadow-canvass-900'
              } transition-all duration-150`}
            >
              <a href="https://campaignnucleus.com/">Campaign Nucleus</a>
            </li>
            <li
              className={`hover:text-shadow-sm ${
                props.surfaceDark ? 'shadow-white' : 'shadow-canvass-900'
              } transition-all duration-150`}
            >
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  isActive
                    ? `text-shadow-sm  ${
                        props.surfaceDark
                          ? 'shadow-white text-white'
                          : 'shadow-canvass-900 text-canvass-900'
                      }`
                    : ''
                }
              >
                FAQs
              </NavLink>
            </li>
            <li
              className={`hover:text-shadow-sm ${
                props.surfaceDark ? 'shadow-white' : 'shadow-canvass-900'
              } transition-all duration-150`}
            >
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? `text-shadow-sm  ${
                        props.surfaceDark
                          ? 'shadow-white text-white'
                          : 'shadow-canvass-900 text-canvass-900'
                      }`
                    : ''
                }
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink to="/request-access">
                <Button type="outline">Get Early Access</Button>
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="block md:hidden">
          <button onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open menu</span>
            <svg
              width="24"
              height="24"
              viewBox="0 -4 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1999_23667)">
                <path
                  d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"
                  fill="#91918E"
                />
              </g>
              <defs>
                <clipPath id="clip0_1999_23667">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>

      {/*Mobile Menu */}
      <Transition appear show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="lg:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-200 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-200 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-neutral-50 px-8 sm:px-12 py-8 md:max-w-md sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                  <img
                    src={logo}
                    className="block md:hidden h-6"
                    alt="MyCanvass"
                  />
                </Link>
                <button onClick={() => setMobileMenuOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1999_24047)">
                      <path
                        d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
                        fill="#91918E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1999_24047">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="flex flex-col gap-2 py-6">
                    <NavLink
                      to="/pricing"
                      onClick={() => setMobileMenuOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-shadow-sm shadow-canvass-900 text-canvass-900 font-semibold py-4 pr-4'
                          : 'font-semibold py-4 pr-4'
                      }
                    >
                      Pricing
                    </NavLink>

                    <a
                      href="https://campaignnucleus.com/"
                      className="font-semibold py-4 pr-4"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Campaign Nucleus
                    </a>

                    <NavLink
                      to="/faq"
                      onClick={() => setMobileMenuOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-shadow-sm shadow-canvass-900 text-canvass-900 font-semibold py-4 pr-4'
                          : 'font-semibold py-4 pr-4'
                      }
                    >
                      FAQs
                    </NavLink>
                    <NavLink
                      to="/contact"
                      onClick={() => setMobileMenuOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-shadow-sm shadow-canvass-900 text-canvass-900 font-semibold py-4 pr-4'
                          : 'font-semibold py-4 pr-4'
                      }
                    >
                      Contact
                    </NavLink>
                  </div>
                  <div className="py-6">
                    <Link
                      to="/request-access"
                      onClick={() => setMobileMenuOpen(false)}
                      className="font-semibold py-4 pr-4"
                    >
                      <Button type="outline">Get Early Access</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
}
