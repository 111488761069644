export function PlatformAccess() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-20 flex flex-col gap-8">
        <h1 className="text-5xl lg:text-7xl font-bold leading-tight xl:leading-normal font-sofia uppercase text-center">
        MYCANVASS PLATFORM ACCESS POLICY
        </h1>
      <p><span>At MyCanvass we are proud to offer a platform that promotes grassroots activism while providing flexible opportunities allowing people to be their own boss and explore the local community.</span></p>
      <p><span>Because we value our relationship with you, we want to be clear, upfront, and transparent about the kinds of activities that can lead to you losing access to certain opportunities on the MyCanvass platform or to the MyCanvass platform itself. If you have additional questions about this policy, please contact <a href="mailto:privacy@mycanvass.com">privacy@mycanvass.com</a>.</span></p>
      <p className="text-center"><strong><u><span>KEEPING THE PLATFORM SAFE AND SECURE</span></u></strong></p>
      <p><span>At MyCanvass, safety and security is paramount. We want you to feel safe when providing services to our customers, our customers to feel comfortable with you representing their interests, and for members of the public to feel comfortable interacting with you. Creating an unsafe or unsecure environment for anyone on the MyCanvass platform or in the communities in which MyCanvass operates is grounds for immediate account deactivation. The behaviors include, but are not limited to:</span></p>
      <div>
          <ul className="list-disc ml-8">
              <li><strong><span>Violence or inappropriate behavior, including abusive language</span></strong><span>: Exhibiting objectively unsafe behavior, including physical, sexual, or verbal assault of a member of the public, another service provider, or any other person.</span></li>
              <li><strong><span>Use of alcohol or drugs</span></strong><span>: Performing services while under the influence of alcohol or drugs.</span></li>
              <li><strong><span>Discrimination and harassment</span></strong><span>: Any discrimination or harassment by or directed at service providers, customers, members of the public, or MyCanvass employees because of race, color, sex, gender, national origin, ancestry, religion, creed, physical or mental disability, medical condition, marital status, sexual orientation, age, profession or any basis protected by federal, state, or local law.</span></li>
              <li><strong><span>Violations of election and campaign finance laws</span></strong><span>: Any activity that could reasonably be considered to constitute: (a)&nbsp;improper use of voter registration files or government data; (b)&nbsp;voter intimidation, undue influence, voter fraud, or illegal assistance with completing ballots; (c)&nbsp;tampering with, altering, destroying, or failing to timely submit certain election-related materials; (d)&nbsp;illegal or unauthorized ballot collection; (e)&nbsp;improper solicitation or receipt of campaign contributions; or (f)&nbsp;receiving compensation in violation of state and local election laws.</span></li>
              <li><strong><span>Use of prohibited technology</span></strong><span>: Except as may be directed by MyCanvass, the use of any equipment that constitutes an auto-dialer or uses artificial or prerecorded voice in connection with performing services.</span></li>
              <li><strong><span>Unsafe driving, biking, or scooter riding</span></strong><span>: Exhibiting objectively unsafe conduct while driving, biking, or scooter riding while performing services on the MyCanvass platform, including texting and driving and failing to be conscientious while using navigation apps. Service providers involved in accidents are evaluated for potential deactivation, depending on all relevant facts and circumstances of the accident(s).</span></li>
              <li><strong><span>Failure to comply with the law or use of the platform for any criminal activity</span></strong><span>: Violating any local, state, or federal law while using the MyCanvass platform, including any applicable criminal or traffic laws. This includes but is not limited to theft of any goods or items, damage to or theft of any third-party property, trespass or unlawful entry, or use of the MyCanvass platform to engage in or assist with any potentially criminal activity.</span></li>
              <li><strong><span>Failure to pass identity and screening criteria</span></strong><span>: Failing to meet MyCanvass&rsquo;s identity verification or screening criteria (whether at the time of account creation or any time thereafter) is grounds for being denied access to the MyCanvass platform or account deactivation if you have already accessed the platform.</span></li>
              <li><strong><span>Disclosing personal information without authorization</span></strong><span>: Using personal information for any purpose other than completing services on the MyCanvass platform, including posting online, saving, or otherwise sharing with any third party, except as may be legally required.</span></li>
          </ul>
      </div>
      <p className="text-center"><strong><u><span>FRAUD OR ABUSE OF THE PLATFORM</span></u></strong></p>
      <p><span>The MyCanvass platform rests on minimum business standards of integrity and fair dealing. We trust service providers to use the MyCanvass platform honestly and with integrity. Accounts of service providers that abuse our services or engage in fraud, or that cause others to do the same, will be deactivated. Examples of abuse and fraud include, but are not limited to:</span></p>
      <div>
          <ul className="list-disc ml-8">
              <li><strong><span>Manipulating promotions</span></strong><span>: Gaming or manipulating any programs, promotions, or any other similar types of offerings through the MyCanvass platform.</span></li>
              <li><strong><span>Providing information that is fraudulent or inaccurate</span></strong><span>: Misrepresenting information during signup, providing false information to MyCanvass or to any person related to the performance of services, creating multiple accounts for the same individual, or using the same phone number as an account already in use.</span></li>
              <li><strong><span>Disrupting the MyCanvass platform</span></strong><span>: Taking any action, either directly or indirectly, that is intended to or does damage, disable, interrupt, overburden, or impair the functionality of the MyCanvass platform or the servers or networks connected to the MyCanvass Platform.</span></li>
              <li><strong><span>Scraping</span></strong><span>: Using any robot, spider, web crawler, extraction software, automated process and/or device to scrape, copy, index, frame, monitor, conduct any systematic retrieval of data or other content from any portion of the MyCanvass platform or its content.</span></li>
              <li><strong><span>Unauthorized access</span></strong><span>: Gaining or attempting to gain unauthorized access to the MyCanvass platform and/or to any account, resource, computer system, and/or network connected to any MyCanvass server. This includes any breach or circumvention of any security or authentication measures MyCanvass may use to prevent or restrict access to the MyCanvass platform.</span></li>
              <li><strong><span>Sharing or providing access to your account</span></strong><span>: Sharing your user ID, password, or any other means of access to your MyCanvass platform account with any other person or entity.</span></li>
              <li><strong><span>Violating third-party rights</span></strong><span>: Using the MyCanvass platform in any way that infringes third-party rights, including copyrights, trade secrets, trademarks, or other rights of any third party, including privacy or publicity rights.</span></li>
          </ul>
      </div>
      <p className="text-center"><strong><u><span>VIOLATING THE TERMS OF YOUR CONTRACT</span></u></strong></p>
      <p><span>Violating any of the terms of your Independent Contractor Agreement or any other agreement or policy that you agreed to as a condition of accessing the MyCanvass platform will serve as a valid basis for losing of access to certain opportunities or deactivation from the MyCanvass platform. This includes but is not limited to:</span></p>
      <div>
          <ul className="list-disc ml-8">
              <li><strong><span>Failure to accurately record completion of a service</span></strong><span>: Marking a service as complete when you did not complete it or indicating that you have completed the requirements of a service when in fact you have not. Likewise, consistent failure to timely mark a service as complete when it has, in fact, been completed.</span></li>
              <li><strong><span>Failure to complete services in a safe or timely manner</span></strong><span>: Failing to provide and complete services on time and safely, while adhering to reasonable expectations of food safety, quality, and health standards as required by the customer and/or applicable law.</span></li>
              <li><strong><span>Failure to adhere to service level requirements</span></strong><span>: Completing any service you accept inaccurately or otherwise failing to adhere to the instructions or specifications of the customer or any other person with whom you interact when performing services.</span></li>
          </ul>
      </div>
      </div>
    </>
  );
}
