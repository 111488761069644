import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NotFound } from './pages/NotFound';
import { Home } from './pages/Home';
import { Pricing } from './pages/Pricing';
import { Faq } from './pages/Faq';
import { Contact } from './pages/Contact';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Terms } from './pages/Terms';
import { PlatformAccess } from './pages/PlatformAccess';
import { RequestAccess } from './pages/RequestAccess';
import { ContractorAgreement } from './pages/ContractorAgreement';

function App() {
  const [isDarkBg, setIsDarkBg] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('contact')) {
      setIsDarkBg(true);
    } else if (location.pathname.includes('request')) {
      setIsDarkBg(true);
    } else {
      setIsDarkBg(false);
    }
    window.scrollTo(0, 0);
    
  }, [location]);

  return (
    <div
      id="top"
      className={`min-h-screen flex flex-col justify-between ${
        isDarkBg === true ? 'bg-canvass-950' : 'bg-page-gradient'
      }`}
    >
      <Header surfaceDark={isDarkBg} />
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/platform-access" element={<PlatformAccess />} />
          <Route path="/contractor-agreement" element={<ContractorAgreement />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/request-access" element={<RequestAccess />} />
          <Route path="/request-basic" element={<RequestAccess planType='basic' />} />
          <Route path="/request-advanced" element={<RequestAccess planType='advanced' />} />
          <Route path="/request-premium" element={<RequestAccess planType='premium' />} />
        </Routes>
      </div>
      {isDarkBg === false && <Footer/>}
    </div>
  );
}

export default App;
