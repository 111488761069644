import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button } from '../components/Button';
import logo from '../assets/logo.svg';
import nucleus from '../assets/nucleus.svg';
import purpleCircleTwo from '../assets/circle-2.png';
import purpleCircleThree from '../assets/circle-3.png';

interface RequestAccessProps {
  planType?: string;
}

export function RequestAccess(props: RequestAccessProps) {
  const [first, setFirst] = useState<any>(null);
  const [last, setLast] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [useAs, setUseAs] = useState<any>('Campaign Representative');
  const [interestedIn, setInterestedIn] = useState<any>('Walking');
  const [loveTo, setLoveTo] = useState<any>('All of the Above!');
  const [thanks, setThanks] = useState(false);

  const validate = () => {
    let error: string = '';
    if (!first) { error += 'Please enter a first name. '}
    if (!last) { error += 'Please enter a last name. '}
    if (!role) { error += 'Please enter a role. '}
    if (!email || !isValidEmail(email)) { error += 'Please enter a valid email. '}
    if (!message) { error += 'Please tell us how we can help. '}
    
    if (error !== '') {
      console.log(error);
      alert(error);
      return false;
    } else {
      return true;
    }
  }

  const isValidEmail = (email: string) => {
    const test = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return test;
  };

  function postDataToMyCanvass() {
    if (!validate()) { return; }

    const url = `https://mcs.campaignnucleus.com/forms/receiver/214bfb0f-02d8-4870-8265-d1dc8a6516e6?`
      + `email=${email}`
      + `&first_name=${first}`
      + `&last_name=${last}`
      + `&your_role=${role}`
      + `&how_can_we_help_you=${message}`
      + `&i_would_be_interested_in_using_mycanvass_as_a=${useAs}`
      + `&interested_in_using_or_learning_more_for=${interestedIn}`
      + `&i_would_love_to=${loveTo}`
      + `&source_url=https://mycanvass.com/request-access`;

    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    const fetchOptions: any = {
      method: 'POST',
      mode: 'no-cors',
      headers: headers,
    };
  
    fetch(url, fetchOptions)
      .then(response => response.json())
      .then((result) => {
        console.log('Success:', result);
        setThanks(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setThanks(true);
    });
  }


  return (
    <div
      className="min-h-screen bg-no-repeat bg-contain bg-[-9999px] sm:bg-[-28em_bottom]"
      style={{ backgroundImage: `url(${props.planType ? purpleCircleThree : purpleCircleTwo})`, mixBlendMode: 'lighten' }}
    >
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-20 flex flex-col items-start sm:items-end gap-8 text-canvass-50 pb-80">
        <div className="flex flex-col gap-8 w-full sm:w-auto">
          <div>
            <h1 className="text-6xl lg:text-5xl leading-tight xl:leading-normal font-sofia uppercase">
              Request Access
            </h1>
          </div>
          {thanks ? 
            <div className='min-h-[500px] max-w-[480px]'>
              <div>Thank you for your message. Please allow 24-48 business hours for a response.</div>
            </div> 
          : 
          <>
            <div className="flex flex-col sm:flex-row gap-6 sm:items-end">
              <div>
                <label
                  htmlFor="first_name"
                  className="ml-px block text-canvass-50 font-extrabold leading-6"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    value={first ? first : ''}
                    onChange={(e) => {setFirst(e.target.value)}}
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="block w-full rounded-3xl border-2 border-[#4C4859] px-5 py-4 text-canvass-50  placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-200 bg-transparent"
                    placeholder="First"
                  />
                </div>
              </div>
              <div className="mt-2">
                <input
                  value={last ? last : ''}
                  onChange={(e) => {setLast(e.target.value)}}
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="block w-full rounded-3xl border-2 border-[#4C4859] px-5 py-4 text-canvass-50  placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-200 bg-transparent"
                  placeholder="Last"
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-6 sm:items-end">
              <div>
                <label
                  htmlFor="role"
                  className="ml-px block text-canvass-50 font-extrabold leading-6"
                >
                  Your Role
                </label>
                <div className="mt-2">
                  <input
                    value={role ? role : ''}
                    onChange={(e) => {setRole(e.target.value)}}
                    type="text"
                    name="role"
                    id="role"
                    className="block w-full rounded-3xl border-2 border-[#4C4859] px-5 py-4 text-canvass-50  placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-200 bg-transparent"
                    placeholder=""
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="ml-px block text-canvass-50 font-extrabold leading-6"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    value={email ? email : ''}
                    onChange={(e) => {setEmail(e.target.value)}}
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-3xl border-2 border-[#4C4859] px-5 py-4 text-canvass-50  placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-200 bg-transparent"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="interested"
                className="ml-px block text-canvass-50 font-extrabold leading-6"
              >
                I would be interested in using MyCanvass as a: *
              </label>
              <div className="mt-2">
                <select
                  className='border-0 cursor-pointer rounded-full drop-shadow-md bg-[#9F55FE] w-full duration-300 hover:bg-indigo-900 focus:bg-indigo-900' 
                  name="interested" 
                  value={useAs ? useAs : null }
                  onChange={(e) => {setUseAs(e.target.value)}}
                  id="interested"  
                >
                  <option>Campaign Representative</option>
                  <option>Canvasser</option>
                  <option value={'Campaign Representive, Canvasser'}>Both</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor="interested"
                className="ml-px block text-canvass-50 font-extrabold leading-6"
              >
                Interested in using or learning more about MyCanvass for: *
              </label>
              <div className="mt-2">
                <select
                  className='border-0 cursor-pointer rounded-full drop-shadow-md bg-[#9F55FE] w-full duration-300 hover:bg-indigo-900 focus:bg-indigo-900' 
                  name="interested" 
                  value={interestedIn ? interestedIn : null }
                  onChange={(e) => {setInterestedIn(e.target.value)}}
                  id="interested"  
                >
                  <option>Walking</option>
                  <option>Calling</option>
                  <option>Ballot Harvesting (where legal)</option>
                  <option value={'Walking, Calling, and Ballot Harvesting'}>All of the Above!</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor="interested"
                className="ml-px block text-canvass-50 font-extrabold leading-6"
              >
                I would love to:*
              </label>
              <div className="mt-2">
                <select
                  className='border-0 cursor-pointer rounded-full drop-shadow-md bg-[#9F55FE] w-full duration-300 hover:bg-indigo-900 focus:bg-indigo-900' 
                  name="interested" 
                  value={loveTo ? loveTo : null }
                  onChange={(e) => {setLoveTo(e.target.value)}}
                  id="interested"  
                >
                  <option>Get Early Access</option>
                  <option>Learn More</option>
                  <option>Be Added to MyCanvass Mailing List</option>
                  <option value={'Early Access, Learn More, Mailing List'}>All of the Above!</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor="role"
                className="ml-px block text-canvass-50 font-extrabold leading-6"
              >
                Other notes or comments?
              </label>
              <div className="mt-2">
                <textarea
                  value={message ? message : ''}
                  onChange={(e) => {setMessage(e.target.value)}}
                  name="role"
                  id="role"
                  className="block w-full h-48 rounded-3xl border-2 border-[#4C4859] px-5 py-4 text-canvass-50  placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-200 bg-transparent resize-none"
                  placeholder=""
                />
              </div>
            </div>
            <div>
              <Button type="fill" onClick={() => {postDataToMyCanvass()}}>Submit</Button>
            </div>
          </> }
        </div>
      </div>

      {/*Kind of silly adding the footer in this way, but it's the only way I could figure to get the fancy overlapping bg img 🤷‍♂️*/}
      <div
        className={`bg-transparent text-canvass-50 flex flex-col px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64`}
      >
        <div className="flex flex-col gap-36">
          <div className="flex flex-col md:flex-row justify-between self-stretch">
            <div className="flex md:flex-col justify-between md:justify-normal gap-8">
              <Link to="/">
                <img src={logo} alt="MyCanvass" className="h-28" />
              </Link>
              <a
                href="https://campaignnucleus.com/"
                className="flex flex-col gap-2"
              >
                <p className="leading-6">powered by</p>
                <img src={nucleus} alt="nucleus" className="h-5" />
              </a>
            </div>

            <div className="flex gap-24 pt-32 md:pt-0 justify-center sm:justify-end">
              <div className="flex flex-col gap-8">
                <NavLink
                  to="/pricing"
                  className={({ isActive }) =>
                    isActive ? 'text-shadow-sm shadow-white text-white' : ''
                  }
                >
                  Pricing
                </NavLink>
                <a
                href="https://campaignnucleus.com/"
              >
                Campaign Nucleus
              </a>
              </div>
              <div className="flex flex-col gap-8">
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive ? 'text-shadow-sm shadow-white text-white' : ''
                  }
                >
                  FAQs
                </NavLink>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? 'text-shadow-sm shadow-white text-white' : ''
                  }
                >
                  Contact
                </NavLink>
              </div>
            </div>
          </div>
          <div className="flex py-12 justify-between self-stretch border-t border-canvass-50 border-opacity-25">
            <p>
              © 2023 MyCanvass LLC. <br className="block md:hidden" />
              All Rights Reserved
            </p>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) =>
                isActive ? 'text-shadow-sm shadow-white text-white' : ''
              }
            >
              Privacy Policy
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
