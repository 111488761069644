import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/logo.svg';
import nucleus from '../assets/nucleus.svg';

interface FooterProps {
  isContactPage?: boolean
}

export function Footer(props: FooterProps) {
  return (
    <div className={`bg-canvass-950 text-canvass-50 flex flex-col px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 ${props.isContactPage ? 'pt-0' :'pt-40'}`}>
      <div className="flex flex-col gap-36">
        <div className="flex flex-col md:flex-row justify-between self-stretch">
          <div className="flex md:flex-col justify-between md:justify-normal gap-8">
            <Link to="/">
              <img src={logo} alt="MyCanvass" className="h-28" />
            </Link>
            <a
              href="https://campaignnucleus.com/"
              className="flex flex-col gap-2"
            >
              <p className="leading-6">powered by</p>
              <img src={nucleus} alt="nucleus" className="h-5" />
            </a>
          </div>

          <div className="flex gap-24 pt-32 md:pt-0 justify-center sm:justify-end">
            <div className="flex flex-col gap-8">
              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  isActive ? 'text-shadow-sm shadow-white text-white' : ''
                }
              >
                Pricing
              </NavLink>
              <a
                href="https://campaignnucleus.com/"
              >
                Campaign Nucleus
              </a>
            </div>
            <div className="flex flex-col gap-8">
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  isActive ? 'text-shadow-sm shadow-white text-white' : ''
                }
              >
                FAQs
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? 'text-shadow-sm shadow-white text-white' : ''
                }
              >
                Contact
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex py-12 justify-between self-stretch border-t border-canvass-50 border-opacity-25">
          <p>© 2023 MyCanvass LLC. <br className='block md:hidden' />All Rights Reserved</p>
          <div className='text-right'>
            <NavLink
              to="/terms"
              className={({ isActive }) =>
                isActive ? 'text-shadow-sm shadow-white text-white' : ''
              }
            >
              Terms
            </NavLink>
            <br/>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) =>
                isActive ? 'text-shadow-sm shadow-white text-white' : ''
              }
            >
              Privacy
            </NavLink>
            <br/>
            <NavLink
              to="/platform-access"
              className={({ isActive }) =>
                isActive ? 'text-shadow-sm shadow-white text-white' : ''
              }
            >
              Access
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
