export function PrivacyPolicy() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-20 flex flex-col gap-8">
        <h1 className="text-5xl lg:text-7xl font-bold leading-tight xl:leading-normal font-sofia uppercase text-center">
        GENERAL PRIVACY POLICY
        </h1>
        <p className="text-center"><strong><u><span>Effective Date</span></u></strong><span>: 11/6/2023</span></p>
        <p className="text-center"><strong><u><span>Last Updated</span></u></strong><span>: 11/6/2023</span></p>
        <p className="text-center"><strong><u><span>OVERVIEW</span></u></strong></p>
        <p  className="lg:text-lg"><span>MyCanvass, LLC (collectively, &ldquo;<u>MyCanvass</u>&rdquo;, &ldquo;<u>we</u>&rdquo;, &ldquo;<u>us</u>&rdquo;, or &ldquo;<u>our</u>&rdquo;) are committed to protecting your privacy. MyCanvass is a technology company that provides voter engagement tools including the MyCanvass mobile application.</span></p>
        <p><span>This Privacy Policy (&ldquo;<u>Policy</u>&rdquo;) explains how we process (e.g., collect, use, disclose and/or protect) personal information when we are acting in the data processing role or capacity of a Controller (as defined below). This Policy specifically applies to the following individuals (collectively, &ldquo;<u>Users</u>&rdquo;):</span></p>
        <ul className="list-decimal ml-8">
            <li><strong><span>Canvassers</span></strong><span>: Individuals who engage the public to solicit donations, disseminate information, gather information, or otherwise provide Services (as defined below) on behalf of our Customers.</span></li>
            <li><strong><span>Customers</span></strong><span>: Individuals who are personnel members of, or otherwise associated with, organizations who have a business relationship with MyCanvass to utilize the Services.</span></li>
            <li><strong><span>Non-Customers</span></strong><span>: Individuals who make donations, receive information, or provide information to a Canvasser in connection with the Services.</span></li>
            <li><strong><span>Visitors</span></strong><span>: Individuals who visit our platforms (e.g., websites, apps, etc.) and/or communicate with us (e.g., when submitting a form or contacting us using the contact information on our platforms).</span></li>
        </ul>
        <p><span>The term &ldquo;<u>Services</u>&rdquo; means the services and activities for Canvassers available on or through mycanvass.com as well as all mobile applications and websites on which this Policy is posted.</span></p>
        <p><span>If you are a Canvasser, your access and use of our platforms is subject to our&nbsp;</span><strong><u><span>Independent Contractor Agreement</span></u></strong><span>&nbsp;(&ldquo;<u>ICA</u>&rdquo;). Please make sure that you have carefully read and understood the ICA before you perform any Services. By performing Services, you accept the ICA, and accept our privacy practices described in this Policy. If you do not feel comfortable with any part of this Policy or the ICA, you must not use or access our platforms.&nbsp;</span></p>
        <p><span>Please note that if you submit or upload personal information for, of, or about another person (e.g., in connection with the Services), by submitting or uploading the personal information to us, you certify that you have the consent or approval of the other person(s) for MyCanvass and you to process the personal information in connection with the purpose(s) for which you submitted or uploaded the personal information, including, without limitation, in connection with the transmission of communications (e.g., via SMS/text messages, email, phone, etc.) by or from you and MyCanvass to the other person(s).</span></p>
        <p className="text-center"><strong><u><span>CATEGORIES OF INFORMATION WE MAY COLLECT</span></u></strong></p>
        <p>
            <strong><span>Canvasser Users</span></strong><span>. In connection with the Services, MyCanvass processes personal information from, of, or about Canvassers in the context of their engagement as independent contractors for MyCanvass. The categories of personal information that we may collect from Canvassers include:</span>
        </p>
        <ul  className="list-decimal ml-8">
            <li><strong><span>Identifiers</span></strong><span>: Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol (&ldquo;<u>IP</u>&rdquo;) address, email address, phone number, account name, social security number, driver&rsquo;s license number, passport number, license plate number, or other similar identifiers.</span></li>
        </ul>
        <ul  className="list-decimal ml-8">
            <li><strong><span>Access Credentials</span></strong><span>: Information connected with your Canvasser account (e.g., username, password, unique identification number, etc.)</span></li>
            <li><strong><span>Additional Information</span></strong><span>: Any additional personal information such as signature, physical characteristics or description, state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, bank account routing number, or any other financial information, medical information, or health insurance information.</span></li>
            <li><strong><span>Identification Documentation</span></strong><span>: Such as drivers&rsquo; license, passport, state identification card, and other similar identification documents that are used for verification purposes.</span></li>
            <li><strong><span>Protected Classifications</span></strong><span>: Characteristics of protected classifications under applicable state or federal law, such as race, color, national origin, religion, age, sex, gender, gender identity, gender expression, sexual orientation, marital status, medical condition, ancestry, disability, citizenship status, and military and veteran status.</span></li>
            <li><strong><span>Online Activity</span></strong><span>: Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding your interaction with an internet website application, or advertisement.</span></li>
            <li><strong><span>Geolocation Data</span></strong><span>: Data that is derived from a device and is used or intended to locate an individual within a geographic area.</span></li>
            <li><strong><span>Sensory Information</span></strong><span>: Audio, electronic, visual, thermal, olfactory, or similar information, such as pictures, videos, audio recordings, etc.</span></li>
            <li><strong><span>Inferences Information</span></strong><span>: Inferences drawn from any of the information identified in this subdivision to create a profile about an individual reflecting the individual&rsquo;s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span></li>
            <li><strong><span>Sensitive Personal Information</span></strong><span>: As these terms are defined under applicable data protection and privacy laws, including precise geolocation (see below for further information); a social security, driver&rsquo;s license, state identification card, or passport number; racial or ethnic origin; religious beliefs; mental or physical health diagnosis; sex life or sexual orientation; or citizenship; etc. We do not use or disclose your sensitive data or sensitive personal information for purposes other than to perform the services reasonably expected by an average user, for security and legal reasons, with your consent, and/or for other purposes permitted by applicable law.</span></li>
            <li><strong><span>Communications Data</span></strong><span>: Such as the nature and content of communications, date and time sent, etc.</span></li>
            <li><strong><span>Survey Response Data</span></strong><span>: Such as demographic information and survey responses that you provide when you voluntarily participate in a survey for us.</span></li>
            <li><strong><span>Preferences Information</span></strong><span>: Such as language preferences, preferred region, communication preferences, delivery preferences, etc.</span></li>
            <li><strong><span>Canvasser Services Information</span></strong><span>: Information related to your use of, and your activities in connection with, the Services, including task information (e.g., number of tasks accepted, number of tasks declined, route information, time to complete tasks, number of tasks completed, etc.), payment amounts for each task, attribution and conversion path, etc.</span></li>
            <li><strong><span>Information Collected from Devices</span></strong><span>: Includes information such the hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.</span></li>
            <li><strong><span>Non-Canvasser Users (Customers, Non-Customers, Visitors)</span></strong><span>. When you use or participate in our Services, or access our platforms, we may collect personal information from you. The categories of personal information that we may collect from non-Canvasser Users include:</span></li>
        </ul>
        <ul  className="list-disc ml-16">
            <li><strong><span>Demographic Data</span></strong><span>: Demographic data about you, such as birth date/age, gender, occupation, and employment status. We may also collect demographic data, such as age group and household composition.</span></li>
            <li><strong><span>Survey Response Data</span></strong><span>: Such as demographic information and survey responses that you provide when you voluntarily participate in a survey for us.</span></li>
            <li><strong><span>Protected Classifications</span></strong><span>: Characteristics of protected classifications under applicable state or federal law, such as race, color, national origin, religion, age, sex, gender, gender identity, gender expression, sexual orientation, marital status, medical condition, ancestry, disability, citizenship status, and military and veteran status.</span></li>
            <li><strong><span>Additional Information</span></strong><span>: Any additional personal information such as signature, physical characteristics or description, address, phone number, email, state identification card number, education, employment, employment history, or certain financial information that you may provide to us.</span></li>
            <li><strong><span>Transaction Information</span></strong><span>: Information related to the use of our Services, including the type of services requested or provided; requests for additional information; and other transaction data that you provide to us.</span></li>
        </ul>
        <p className="text-center"><strong><u><span>HOW WE COLLECT INFORMATION</span></u></strong></p>
        <ol start={1}>
            <li><strong><span>Direct Collection</span></strong><span>.&nbsp;</span><span>We and our third-party service providers collect information you provide to us when you perform or participate in Services, request information from us, register with us, participate in activities on, or otherwise use or access, our platforms, respond to surveys, attend in-person events, speak with us, or otherwise interact with us.</span></li>
            <li><strong><span>Tracking Technologies</span></strong><span>.&nbsp;</span><span>We and our third-party service providers may collect information about your activity, or activity on devices associated with you, on our platforms, using tracking technologies such as cookies, Flash cookies, pixels, tags, software development kits, application program interfaces, and web beacons. At present, we do not respond to Do No Track (&ldquo;<u>DNT</u>&rdquo;) signals as there are currently no standards for DNT signals. If a standard is established and accepted, we may reassess how to respond to DNT signals. Tracking data may be used by us for many purposes, including, without limitation, to: (i)&nbsp;provide useful features to simplify your experience when you return to our platforms; (ii)&nbsp;deliver relevant content and tasks based upon your preferences, usage patterns, and location; (iii)&nbsp;monitor, evaluate, and optimize our Services; and; (iv)&nbsp;analyze traffic on a website. Examples of online tracking technologies include:</span></li>
        </ol>
        <ul  className="list-decimal ml-8">
            <li><strong><span>Cookies</span></strong><span>. Cookies are pieces of information that a website places on the hard drive of your computer when you visit a website. Cookies may involve the transmission of information from us to you and from you directly to us, to another party on our behalf, or to another party in accordance with its privacy policy. Most browsers are initially set to accept cookies and allow local storage, but you should be able to change your settings to notify you when a cookie is being set or updated, local storage is being used, and/or to block cookies and/or the use of local storage altogether. Please consult the &ldquo;Help&rdquo; section of your browser for more information. Please note that by blocking any or all cookies you may not have access to certain features, content and/or other personalization available to you as part of our Services.</span></li>
        </ul>
        <ul  className="list-decimal ml-8">
            <li><strong><span>Flash Cookies</span></strong><span>. We, or our third-party service providers, may use local shared objects, sometimes known as Flash cookies, to store your preferences or display content based upon what you view on our site to personalize your visit. Our third-party service providers also may use Flash cookies to collect and store information. Cookie management tools provided by your browser will not remove Flash cookies. Learn how to manage privacy and storage settings for Flash cookies by clicking&nbsp;</span><span><a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"><span>here</span></a></span><span>. Please note that by blocking any or all Flash cookies you may not have access to certain features, content and/or other personalization available to you as part of our Services.</span></li>
            <li><strong><span>Web Beacons</span></strong><span>.<strong>&nbsp;</strong>Web beacons are small pieces of data that are embedded in images on the pages of sites. Web beacons may involve the transmission of information directly to us, to another party on our behalf, or to another party in accordance with its privacy policy. We, or our third-party service providers, may use web beacons to bring together information we collect about you.</span></li>
      
            <li><strong><span>Analytics Tools</span></strong><span>. We, and our third-party service providers, may collect information using analytics tools, including when you visit our sites or use our Applications, on third-party sites or platforms.</span></li>
            <li><strong><span>Third-Party Data.</span></strong><span>&nbsp;We, and our third-party service providers, may acquire information from other sources, including publicly available sources, to update or supplement the information you provided or we collected.</span></li>
        </ul>

        <p className="text-center"><strong><u><span>HOW WE USE INFORMATION WE COLLECT</span></u></strong></p>
        <p><span>We are responsible for and may use your information for the purposes described in this Policy. Our third-party service providers may access your information where they perform services on our behalf.</span></p>
        <p><strong><span>General Use</span></strong><span>. Consistent with applicable law and choices and controls that may be available to you, we may or our third-party service providers may use the information we collect from you, or from devices associated with you, to:</span></p>

        <ul  className="list-decimal ml-8">
            <li><span>Allow you to use, perform, or participate in the Services, including providing you with information you request, view, or engage with;</span></li>
            <li><span>Communicate with you about your account or transactions with us or our third-party service providers and send you information or request feedback about features on our platforms, or changes to our policies;</span></li>
            <li><span>Facilitate contributions to our Customers;</span></li>
            <li><span>Send messages, text messages, surveys, polls, correspondence, and notifications to you regarding the Services;</span></li>
            <li><span>Personalize content and experiences;</span></li>
            <li><span>Detect, investigate, and prevent activities that may violate our policies, pose safety issues, or be fraudulent or illegal;</span></li>
            <li><span>Operate, understand, optimize, develop, or improve our operations and Services; and</span></li>
            <li><span>Comply with applicable law, including election and campaign finance laws.</span></li>
        </ul>

        <p><strong><span>Marketing and Advertising</span></strong><span>. In addition to the uses described above, we may engage in the following activities:</span></p>
        <ul  className="list-decimal ml-8">
            <li><strong><span>Look-Alike Modeling</span></strong><span>. We may use personal information and/or link personal information to a third-party cookie or other similar technology to develop audience insights and/or look-alike models in connection with our marketing campaigns, which models are used to market or advertise the Services on third party platforms (e.g., websites, apps, etc.) to people who exhibit similar characteristics with the look-a-like modeled data.</span></li>
            <li><strong><span>Audience Insight/Ad Measurement</span></strong><span>. We may use the personal and transactional information collected from or about you and your use of our Services and platforms to gather audience insights and engage in ad effectiveness measurement. In connection with this activity, we may share personal and transactional information about you and your use of our Services and platforms with our service providers, processors and Customers for the purposes of ad insight and ad effectiveness measurement reporting (either on an individual user level or an aggregate level), which will be used to create, enhance, and improve our Services and platforms and to create, enhance, improve, and implement marketing campaigns and content.</span></li>
            <li><strong><span>Cross-Context Behavioral Advertising</span></strong><span>. We may engage in targeted marketing or advertising to a User based upon the User&rsquo;s personal information obtained from the User&rsquo;s activity across another company&rsquo;s distinctly-branded websites, applications, or services, however, such activities are only on our behalf and for the benefit of us, our Customers, and the Services.</span></li>
        </ul>

        <p className="text-center"><strong><u><span>HOW WE SHARE INFORMATION WITH THIRD PARTIES</span></u></strong></p>

        <p><span>We will not share your information with a third party except as provided in this Policy. Subject to applicable law and choices and controls that may be available to you under applicable law, we may, without additional notice to you, share your information in the following circumstances:</span></p>
        <ul  className="list-decimal ml-8">
            <li><span>When required or requested by our Customers.</span></li>
            <li><span>When we, our Customers, or third-party service providers, file reports with federal or state regulators to comply with applicable laws and regulations;</span></li>
            <li><span>When you expressly allow us to share your information with a third-party so that they can send you additional information;</span></li>
            <li><span>When third parties perform services on our behalf or on behalf of our Customers, such as door-knocking, marketing and advertising, solicitation of contributions, web design and maintenance, donor service, payment processing, and survey, polling, and research;</span></li>
            <li><span>When we transfer, sell or license, directly or indirectly, in connection with any reorganization, formation of a successor organization, asset sale, license, or other transfer, financing or lending transaction, or in any other situation where your personal information may be disclosed, licensed, pledged, or transferred as one of our assets;</span></li>
            <li><span>When we transfer, sell, or license information to a broker, a political committee, or other organization; and</span></li>
            <li><span>When we share your information with third parties to enforce our policies and agreements, to ensure the safety and security of third parties, to protect our rights and property and the rights and property of third parties, to comply with legal process, or in other cases if we believe in good faith that disclosure is required by law.</span></li>
        </ul>

        <p className="text-center"><strong><u><span>YOUR CHOICES</span></u></strong></p>

        <p><span>We provide you the ability to exercise certain controls and choices regarding our collection, use and sharing of your information. Subject to laws applicable to us and you, your controls and choices may include:</span></p>
        <ul  className="list-decimal ml-8">
            <li><span>Correcting, updating, and deleting your account;</span></li>
            <li><span>Choosing or changing your choices for subscriptions, newsletters, and alerts;</span></li>
            <li><span>Opting out of receiving messages from us;</span></li>
            <li><span>Disabling tracking technologies on your web browser;</span></li>
            <li><span>Opting out of a sale of your information; and</span></li>
            <li><span>Requesting access to the information we hold about you and/or that we amend or delete it.</span></li>
        </ul>

        <p className="text-center"><strong><u><span>CHILDREN&rsquo;S PRIVACY</span></u></strong></p>

        <p><span>Our Services does not intend for children to be our primary audience and we do not knowingly contact or collect information from children under the age of 13 without appropriate notice and consent of a parent or legal guardian. If we are informed that we have unintentionally received information from a child under the age of 13, we will delete it. If you want to notify us of our possible receipt of information by children under the age of 13, please contact us at <a href="mailto:privacy@mycanvass.com">privacy@mycanvass.com</a> with &ldquo;CHILDREN&rsquo;S PRIVACY REQUEST&rdquo; in the subject line. Please click&nbsp;</span><span><a href="https://www.ftc.gov/tips-advice/business-center/privacy-and-security/children%27s-privacy">here</a></span><span>&nbsp;for information from the Federal Trade Commission about protecting children&rsquo;s privacy.</span></p>

        <p className="text-center"><strong><u><span>STATE PRIVACY RIGHTS</span></u></strong></p>

        <p><strong><span>Privacy Rights for Nevada Residents.</span></strong><span>&nbsp;If you are a Nevada resident, Nevada Revised Statutes Section 603A gives you the right to submit a verified request to us, directing us not to make any sale of certain personal information, as defined under this law, that we have collected or may collect about you. To submit such a request, Nevada residents can email us <a href="mailto:privacy@mycanvass.com">privacy@mycanvass.com</a> with &ldquo;NEVADA OPT-OUT REQUEST&rdquo; in the subject line.</span></p>
        <p><strong><span>Privacy Rights for California Residents.</span></strong><span>&nbsp;If you are a California Resident, California Civil Code Section 1798.83 permits you to request certain information regarding our disclosure of personal information, as defined under this law, to third parties for their direct marketing purposes. To submit such a request, please contact us at <a href="mailto:privacy@mycanvass.com">privacy@mycanvass.com</a> with &ldquo;CALIFORNIA PRIVACY REQUEST&rdquo; in the subject line.</span></p>

        <p className="text-center"><strong><u><span>DATA SECURITY PRACTICES</span></u></strong></p>
        <p><span>The security, integrity, and protection of your information are extremely important to us. We have implemented technical, administrative, and physical security measures that are designed to protect your Personal Information from unauthorized access, disclosure, use, and modification. Please be aware that, despite our best efforts, no security measures are perfect or impenetrable. We and our third-party service providers will retain your information for the length of time needed to fulfill the purposes outlined in this Policy unless a longer retention period is required or permitted by law.</span></p>

        <p className="text-center"><strong><u><span>INTEGRATION OF THIRD PARTY PLATFORMS AND SERVICES</span></u></strong></p>
        <p><span>Our Services may be linked to, rely on, and/or be integrated with websites, applications, interfaces, services, and/or platforms operated by third-parties including, without limitation, service providers or Customers. We are not responsible for the privacy practices of any website, application, interface, service, or platform operated by a third party. Once you leave our site via a link, access a third-party application, interface, service, or platform, including websites operated by service providers, you should check the applicable privacy policy of such third party to determine, among other things, how they will handle any information they may collect from you.</span></p>

        <p className="text-center"><strong><u><span>CHANGES TO THIS POLICY</span></u></strong></p>
        <p><span>ANY INFORMATION THAT IS COLLECTED VIA OUR SERVICES IS COVERED BY THE PRIVACY POLICY IN EFFECT AT THE TIME SUCH INFORMATION IS COLLECTED. WE MAY REVISE THIS PRIVACY POLICY FROM TIME TO TIME. IF WE MAKE ANY MATERIAL CHANGES TO THIS PRIVACY POLICY, INCLUDING ANY CHANGE THAT WE PROPOSE THAT WILL HAVE RETROACTIVE EFFECT, WE WILL NOTIFY YOU OF THOSE CHANGES BY POSTING THEM ON OUR SITES OR BY SENDING YOU AN EMAIL OR OTHER NOTIFICATION, AND WE WILL UPDATE THE &ldquo;LAST UPDATED DATE&rdquo; ABOVE TO INDICATE WHEN THOSE CHANGES WERE MADE.</span></p>

        <p className="text-center"><strong><u><span>INTERNATIONAL USE</span></u></strong></p>
        <p><span>Our Services are controlled, operated, and administered entirely within the United States, and intended for adult United States citizens and permanent residents only. If you visit, access, interact with, and/or otherwise participate in our Services from a location outside the United States, please be advised that any information you provide in connection with any such activity may be processed in and/or transferred to the United States of America and/or other territories and locations, where privacy protections may not be as comprehensive as those in the territory or location where you are located. By participating in our Services or accessing any of our platforms, you affirmatively consent to the transfer, use, disclosure, provision, and other administration of your information as described herein.</span></p>

        <p className="text-center"><strong><u><span>CONTACT US</span></u></strong></p>
        <p><span>If you have any questions about our privacy practices or this Privacy Policy, please contact us at:</span></p>
        <p><span>Email: <a href="mailto:privacy@mycanvass.com">privacy@mycanvass.com</a></span></p>
        <p><span>OR</span></p>
        <p><span>Mail: 1512 E. Broward Blvd, Suite 104b, Ft. Lauderdale, FL 33301</span></p>
      </div>
    </>
  );
}
