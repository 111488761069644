import { Link } from 'react-router-dom';
import { AccessBanner } from '../components/AccessBanner';
import { Button } from '../components/Button';

import heroImg from '../assets/hero-img-1.png';
import bannerOne from '../assets/banner-img-1.png';
import bannerTwo from '../assets/banner-img-2.png';
import cardOne from '../assets/card-img-1.png';
import cardTwo from '../assets/card-img-2.png';
import cardThree from '../assets/card-img-3.png';

import sparks from '../assets/sparks.svg';
import networking from '../assets/networking.svg';
import eye from '../assets/eye-recognition.svg';
import disperse from '../assets/disperse.svg';
import loan from '../assets/loan.svg';
import exchange from '../assets/exchange.svg';
import checkCircle from '../assets/check-circle.svg';

export function Home() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-20 flex flex-col items-center gap-20 sm:gap-40">
        <div className="w-full py-6 sm:py-12 lg:bg-hero-image bg-right bg-no-repeat bg-contain flex flex-col items-start">
          <h1 className="text-h1small font-sofia">
            <span className="text-canvass-500">EARN MONEY </span>
            <br className="hidden md:block" />
            <span>CAMPAIGNING FOR </span>
            <br className="hidden md:block" />
            <span>YOUR CANDIDATE </span>
          </h1>
          <p className="mt-14 mb-12 max-w-sm lg:max-w-md text-xl">
            Promote political campaigns that matter, aided by AI precision and
            targeted scripts. Make a difference—and money—at the same time.
          </p>
          <Link to="/request-access"><Button type="fill">Get Early Access</Button></Link>
          <img
            src={heroImg}
            alt="a woman smiles while looking at her cell phone"
            className="block lg:hidden mt-6"
          />
        </div>

        <div className="w-full gap-10 sm:gap-20 flex flex-col items-center text-center justify-center pb-14 sm:pb-28">
          <h2 className="text-6xl font-sofia uppercase">
            The Future of Campaigning
          </h2>
          <div className="flex justify-center text-center gap-14 flex-wrap">
            <div className="flex flex-col items-center gap-6">
              <img src={sparks} alt="sparks" />
              <div className="flex flex-col items-center gap-4">
                <p className="text-lg font-extrabold">Automation</p>
                <p className="max-w-xs">
                  Our smart AI tools take care of tedious tasks so you can focus
                  on making a difference. It's politics, simplified.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-6">
              <img
                src={networking}
                alt="three dots in a triangular pattern connected by lines"
              />
              <div className="flex flex-col items-center gap-4">
                <p className="text-lg font-extrabold">Collaboration</p>
                <p className="max-w-xs">
                  Coordinate seamlessly with your team and other campaigners.
                  United we stand, united we make a change.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-6">
              <img src={eye} alt="the outline on an eye" />
              <div className="flex flex-col items-center gap-4">
                <p className="text-lg font-extrabold">Transparency</p>
                <p className="max-w-xs">
                  Keep track of your campaign progress with real-time updates.
                  Know your impact, every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between bg-canvass-950 text-canvass-50">
        <div
          className="hidden md:block flex-grow basis-1/2 bg-cover bg-center"
          style={{ backgroundImage: `url(${bannerOne})` }}
        />
        <div className="flex flex-col justify-center gap-16 flex-grow basis-1/2 pl-8 lg:pl-16 xl:pl-24 2xl:pl-32 pr-4 py-40">
          <h2 className="text-6xl font-sofia uppercase">
            <span className="text-white">Your cause.</span>
            <br />
            <span className="text-canvass-500">Your voice.</span>
          </h2>
          <div className="flex flex-col gap-8">
            <p className="font-extrabold text-xl">
              Deliver Impact Where It Matters
            </p>
            <p className="max-w-lg">
              With MyCanvass, campaigning is not just about politics, it's about
              making a difference. Our platform enables you to target the issues
              that matter most to you and your community.
            </p>
          </div>
          <div>
            <Link to="/request-access"><Button type="fill">Get Early Access</Button></Link>
          </div>
        </div>
      </div>

      <div className="w-full gap-14 sm:gap-20 flex flex-col items-center text-center text-canvass-50 justify-center py-40 bg-canvass-800">
        <h2 className="text-6xl text-white font-sofia uppercase px-8">
          Work when you want,
          <br />
          Deliver Real Impact.
        </h2>
        <div className="flex justify-center text-center gap-14 flex-wrap px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64">
          <div className="flex flex-col items-center gap-6">
            <img src={disperse} alt="arrows pointing outward from a center" />
            <div className="flex flex-col items-center gap-4">
              <p className="text-lg font-extrabold">Expand as Needed</p>
              <p className="max-w-xs">
                Scale your efforts up or down as the political landscape
                changes. Flexibility is our forte.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6">
            <img src={loan} alt="a hand holding a coin" />
            <div className="flex flex-col items-center gap-4">
              <p className="text-lg font-extrabold">Pay for Performance</p>
              <p className="max-w-xs">
                Your effort equals your earnings. With our pay-for-performance
                model, you get rewarded for your hard work.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6">
            <img src={exchange} alt="arrows between two people" />
            <div className="flex flex-col items-center gap-4">
              <p className="text-lg font-extrabold">
                Coordinate with other Campaigns
              </p>
              <p className="max-w-xs">
                Keep track of your campaign progress with real-time updates.
                Know your impact, every step of the way.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex bg-canvass-950 text-canvass-50">
        <div className="flex-grow basis-1/2 py-40 flex justify-end">
          <div className="flex flex-col gap-16 pr-10 lg:pr-16 xl:pr-24 2xl:pr-32 pl-8">
            <h2 className="text-6xl font-sofia uppercase">
              <span className="text-white">A Safe Platform to </span>
              <br className="hidden xl:block" />
              <span className="text-canvass-500">Amplify Your Voice.</span>
            </h2>
            <div className="flex flex-col gap-8">
              <p className="font-extrabold text-xl">
                Ensuring Authenticity and Trust
              </p>
              <p className="max-w-lg">
                MyCanvass takes the integrity of political campaigning
                seriously. Our robust fraud detection systems ensure the
                authenticity of each interaction, maintaining a trustworthy
                platform for campaigners and voters alike.
              </p>
            </div>
            <div>
              <Link to="/request-access"><Button type="fill">Get Early Access</Button></Link>
            </div>
          </div>
        </div>
        <div
          className="hidden md:block flex-grow basis-1/2 bg-cover bg-center"
          style={{ backgroundImage: `url(${bannerTwo})` }}
        />
      </div>

      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-40 flex flex-col items-center gap-24">
        <div className="flex flex-col lg:flex-row justify-between w-full items-center gap-6 lg:gap-24">
          <div className='flex-shrink'><img src={cardOne} alt=""/></div>
          <div className="flex flex-col flex-grow gap-9 lg:gap-14">
            <h2 className="text-6xl font-sofia uppercase text-center sm:text-left">
              <span>Find your </span>
              <br className="hidden xl:block" />
              <span>candidate</span>
            </h2>
            <div className="flex flex-col gap-8">
              <p className="font-extrabold text-xl">
                ... or discover new campaigns to support!
              </p>
              <ul className="space-y-3 leading-6">
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Filter and sort by distance, party affiliation, earnings, and
                  more.
                </li>
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Apply to make calls, visit homes, or both!
                </li>
                <li className="flex">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none mr-3"
                    aria-hidden="true"
                  />
                  Once you’re approved, start canvassing on your schedule.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row justify-between w-full items-center gap-6 lg:gap-32">
          <div className="flex flex-col flex-grow gap-9 lg:gap-14 max-w-lg">
            <h2 className="text-6xl font-sofia uppercase text-center sm:text-left">
            Make Phone Calls
            </h2>
            <div className="flex flex-col gap-8">
              <p className="font-extrabold text-xl">
                ...from the comfort of your own home – or in a spare moment on the go!
              </p>
              <ul className="space-y-3 leading-6">
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Follow a guided script to share your candidate’s vision - no prep required!
                </li>
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Ask pre-written questions to gauge interest and discover voter concerns, then enter responses as you go. 
                </li>
                <li className="flex">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none mr-3"
                    aria-hidden="true"
                  />
                  Easily view your earnings and start new calls.
                </li>
              </ul>
            </div>
          </div>
          <div className='flex-shrink'><img src={cardTwo} alt="a woman holding a smart phone in her hand"/></div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between w-full items-center gap-6 lg:gap-32">
          <div className='flex-shrink'><img src={cardThree} alt="a map with a lot of information on it"/></div>
          <div className="flex flex-col flex-grow gap-9 lg:gap-14 max-w-lg">
            <h2 className="text-6xl font-sofia uppercase text-center sm:text-left">
            Visit Homes
            </h2>
            <div className="flex flex-col gap-8">
              <p className="font-extrabold text-xl">
                ...and make a bigger impact while earning higher rates!
              </p>
              <ul className="space-y-3 leading-6">
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Find nearby homes you want to visit and start knocking on doors.
                </li>
                <li className="flex gap-x-3">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  Read from a script and ask questions – or memorize it to make a better impression.
                </li>
                <li className="flex">
                  <img
                    src={checkCircle}
                    alt="✓"
                    className="h-6 w-5 flex-none mr-3"
                    aria-hidden="true"
                  />
                  Help voters submit their ballots and earn extra $$!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <AccessBanner />
    </>
  );
}
