export function Terms() {
  return (
    <>
      <div className="px-8 sm:px-12 lg:px-16 xl:px-40 2xl:px-64 py-20 flex flex-col gap-8">
        <h1 className="text-5xl lg:text-7xl font-bold leading-tight xl:leading-normal font-sofia uppercase text-center">
        TERMS OF SERVICE
        </h1>
        
        <p><strong>MYCANVASS TERMS OF SERVICE</strong></p>
        <p><strong><span>IMPORTANT NOTICE</span></strong><span>: MYCANVASS, LLC (&ldquo;<u>MYCANVASS</u>&rdquo;) IS A LIMITED LIABILITY COMPANY INCORPORATED AND EXISTING UNDER THE LAWS OF THE STATE OF DELAWARE. BY CLICKING ON THE &ldquo;ACCEPT&rdquo; BUTTON OR USING THE MYCANVASS PLATFORM, YOU ARGREE TO THESE TERMS OF SERVICE, WHICH SHALL ALSO CONSTITUTE AN END USER LICENSE AGREEMET (&ldquo;<u>EULA</u>&rdquo;), BINDING YOU AS THE END USER (AS DEFINED BELOW). YOU ARE NOT REQUIRED TO ACCEPT THIS EULA, BUT UNLESS AND UNTIL YOU DO, YOU WILL NOT BE ABLE TO ACCESS THE MYCANVASS PLATFORM.&nbsp;</span></p>
        <p><span>THIS IS A LICENSING AGREEMENT AND NOT A PURCHASE CONTRACT.</span></p>
        <p><strong><u><span>AGREEMENT</span></u></strong></p>
      
        <div>
            <ol>
                <li className="text-center"><strong><u><span>1.Definitions</span></u></strong><span>. Capitalized terms used in this EULA shall have the meanings ascribed below. Any capitalized terms used but not otherwise defined herein shall have the meanings ascribed to those terms in the Independent Contractor Agreement.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><strong><span>&ldquo;Customer&rdquo;</span></strong><span>&nbsp;means the organization on whose behalf the End User has access to the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;End User Content&rdquo;</span></strong><span>&nbsp;means the data input by the End User for the purpose of using the MyCanvass Platform or facilitating the End User&rsquo;s use of the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;End User&rdquo;</span></strong><span>&nbsp;means the end user who is authorized by MyCanvass and the Customer to use the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;License Term&rdquo;</span></strong><span>&nbsp;has the meaning provided in Section 4(A) hereof.</span></li>
                <li><strong><span>&ldquo;MyCanvass Content&rdquo;</span></strong><span>&nbsp;means the information entered by MyCanvass or on behalf of MyCanvass into the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;MyCanvass IP&rdquo;</span></strong><span>&nbsp;means the intellectual property rights in and to the MyCanvass Platform, the Software and the Services and any updates or modifications thereto.</span></li>
                <li><strong><span>&ldquo;MyCanvass Platform Access Policy&rdquo;</span></strong><span>&nbsp;means the policy governing your use of the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;MyCanvass Platform&rdquo;</span></strong><span>&nbsp;means the web-based platform licensed by MyCanvass to the End User under this EULA, including MyCanvass IP and MyCanvass Content.</span></li>
                <li><strong><span>&ldquo;Privacy Dashboard&rdquo;</span></strong><span>&nbsp;means the dashboard available as part of the MyCanvass admin Platform that sets out the privacy setting for the Services.</span></li>
                <li><strong><span>&ldquo;Services&rdquo;</span></strong><span>&nbsp;means the services provided by MyCanvass under this EULA including the provision of access to the MyCanvass Platform.</span></li>
                <li><strong><span>&ldquo;Software&rdquo;</span></strong><span>&nbsp;means the software licensed by MyCanvass to the End User under this EULA which forms part of the MyCanvass Platform including databases, data schemas, and data models.</span></li>
                <li><strong><span>&ldquo;Subscription Agreement&rdquo;</span></strong><span>&nbsp;means the commercial agreement between MyCanvass and the Customer for use of and access to the MyCanvass Platform.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>2. End User License</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>MyCanvass hereby grants to the End User a non-exclusive, non-transferable license to use the MyCanvass Platform during the License Term solely for the Customer&rsquo;s purposes.</span></li>
                <li><span>The End User shall keep the password for use of the MyCanvass Platform secure and confidential.</span></li>
                <li><span>The End User shall not access, store, distribute, or transmit any viruses or any material when using the MyCanvass Platform that is considered illegal, harmful, or facilities illegal activity.</span></li>
                <li><span>The End User shall use the MyCanvass Platform in accordance with the MyCanvass Platform Access Policy and the Independent Contractor Agreement.</span></li>
                <li><span>Except to the extent expressly provided under this EULA or the Independent Contractor Agreement, the End User shall not:</span></li>
            </ol>
        </div>

        <div>
            <ol className="list-disc ml-16">
                <li><span>Attempt to modify, duplicate, create derivative works from, or distribute all or any portion of the MyCanvass Platform;</span></li>
                <li><span>Attempt to compile, disassemble, or reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software;</span></li>
                <li><span>Access all or any part of the MyCanvass Platform in order to build a product or service that competes with the MyCanvass Platform;</span></li>
                <li><span>Use the MyCanvass Platform to provide services to third-parties or in any way not connected to the Customer; or</span></li>
                <li><span>License, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the MyCanvass Platform available to any third-party.</span></li>
            </ol>
        </div>

        <div>
            <ol className="list-decimal ml-8" start={6}>
                <li><span>To be eligible to use the MyCanvass Platform, the End User undertakes to meet the following criteria and represent and warrant to:</span></li>
            </ol>
        </div>

        <div>
            <ol className="list-disc ml-16">
                <li><span>Maintain only one End User account at any given time;</span></li>
                <li><span>Provide all equipment, software, and internet access necessary to use the MyCanvass Platform;</span></li>
                <li><span>Comply with all data protection rules and regulations applicable at the time of use; and</span></li>
                <li><span>Comply with all laws applicable to elections and campaign finance at the time of use.</span></li>
            </ol>
        </div>

        <div>
            <ol className="list-decimal ml-8" start={7}>
                <li><span>End User understands and agrees that use of the use of the MyCanvass Platform may entail the application of certain incidental usage charges during the installation and use of the Service. These charges may, among others, be levied by mobile network operators or internet service providers. The End User should consult their mobile data or internet data plan to identify any charges which may be incurred prior to the installation and operation of these Services. MyCanvass shall have no responsibility for such charges.</span></li>
                <li><span>End User hereby agrees to indemnify and hold MyCanvass and its licensors harmless from and against any liabilities, damages, judgments, losses, costs and expenses (including reasonable legal fees) arising from the End User&rsquo;s use of the MyCanvass Platform in a manner inconsistent with this EULA.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>3. Location-Based Services</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>In accordance with the CTIA (The International Association for the Wireless Telecommunications Industry) Best Practices and Guidelines for Location Based Services MyCanvass is required to explain the location based aspect of the Service to the End User. The express consent of the End User will be obtained before access to the location based aspect of the MyCanvass Platform is provided.</span></li>
                <li><span>The location based services form an integral part of the operational aspect of the Services. In order for the location based aspect of the Service to operate correctly, MyCanvass must be permitted to communicate with the phone, tablet, and/or computer on which MyCanvass Platform is used. By doing so, MyCanvass will attempt to identify device location. This requires the collection, use and sharing, among other things of precise coordinate data, including real time geographic location of the device with MyCanvass. This information is retained for the duration of this EULA and as further detailed in the Privacy Dashboard, in order to facilitate the efficacy of the Services.</span></li>
                <li><span>The End User hereby authorizes MyCanvass to collect and use the End User location data in order to provide the Services. The End User will be provided with access to the Privacy Dashboard where the End User or the Client may disconnect a device from the location based aspect of the Services. Instructions of such opt out will be detailed on the Privacy Dashboard and on the settings of the MyCanvass App. If this action is taken MyCanvass may not be able provide the Services and the Customer&rsquo;s data may be undermined as a consequence.</span></li>
                <li><span>MyCanvass does not represent that these location-based services are suitable for emergency situations and should not be relied upon as such. MyCanvass shall not be liable for any reliance placed by an individual on these location-based services.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>4. License Term</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>The &ldquo;<u>License Term</u>&rdquo; shall begin on the date of acceptance of this EULA and shall continue for as long as MyCanvass makes the MyCanvass Platform available to the End User.</span></li>
                <li><span>The End User&rsquo;s access may automatically deactivate and become non-operational at the end of the License Term.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>5. Third-Party Providers</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>The End User acknowledges that as part of using the Services, the End User may be able to access or integrate with the website content of, correspond with, and purchase products and services from, third parties via third-party websites or applications. The End User accesses this information at its own risk.</span></li>
                <li><span>MyCanvass makes no representation or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website, or any transactions completed, and any contract entered into by the End User, with any such third party. Any contract entered into and any transaction completed via any third-party website is between the End User and the relevant third party, and not MyCanvass.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>6. MyCanvass&rsquo;s Obligations</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>MyCanvass undertakes to provide the MyCanvass Platform with reasonable care and skill.</span></li>
                <li><span>Notwithstanding the foregoing:</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-disc ml-16">
                <li><span>MyCanvass does not warrant that the End User&rsquo;s use of the MyCanvass Platform will be uninterrupted or error-free; nor that the MyCanvass Platform, the Services, the MyCanvass Content, and/or the information obtained by the End User through the MyCanvass Platform will meet the End User&rsquo;s requirements; and</span></li>
                <li><span>MyCanvass is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks or facilities, including the internet.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={3}>
                <li><span>MyCanvass reserves the right to modify the MyCanvass Platform at any time. MyCanvass will make available to the End User all improvements from time to time made available by it to other users.</span></li>
                <li><span>All warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this EULA.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>7. Proprietary Rights</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>The End User acknowledges and agrees that MyCanvass and/or its licensors own all intellectual property rights in the MyCanvass IP. Except as expressly stated herein, this EULA does not grant the End User any rights to, or in, patents, copyrights, database rights, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licenses in respect of the MyCanvass IP.</span></li>
                <li><span>MyCanvass or the Client (as applicable) shall own all rights, title and interest in and to all of the End User Content.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>8. Limitation of Liability</span></u></strong><span>.&nbsp;</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>This Section 8 sets out the entire financial liability of MyCanvass (including any liability for the acts or omissions of its employees, contributing experts, agents and sub-contractors) to the End User.</span></li>
                <li><span>Nothing in this EULA excludes the liability of MyCanvass, for death or personal injury caused by MyCanvass&rsquo;s gross negligence, willful misconduct, or fraud.</span></li>
                <li><span>MyCanvass shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this EULA.</span></li>
                <li><span>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MYCANVASS AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS EULA, THE MYCANVASS PLATFORM, THE INABILITY TO USE THE MYCANVASS PLATFORM, OR ANY TRANSACTIONS ENTERED INTO THROUGH THE MYCANVASS PLATFORM. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MYCANVASS&rsquo;S OR ITS AFFILIATES&rsquo; AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS EULA OR THE TRANSACTIONS CONTEMPLATED HEREBY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, PRODUCT LIABILITY OR OTHER THEORY), WARRANTY OR OTHERWISE, EXCEED THE AMOUNT OF FEES EARNED BY MYCANVASS IN CONNECTION WITH YOUR PERFORMANCE OF SERVICES DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.</span></li>
                <li><span>THE LIMITATIONS OF LIABILITY CONTAINED IN THIS EULA ARE INCLUSIVE OF, AND NOT INCREMENTAL TO, ANY OTHER LIMITATION OF LIABILITY AGREED TO BETWEEN MYCANVASS AND THE END USER.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>9. Term and Termination</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>This EULA shall, unless otherwise terminated in accordance with its terms shall commence on the date of acceptance of this EULA and continue for the License Term.</span></li>
                <li><span>MyCanvass may grant access to the MyCanvass Platform on a trial basis under this EULA. Such access may be terminated by MyCanvass at any time.</span></li>
                <li><span>MyCanvass reserves the right to refuse to license the MyCanvass Platform to the End User or to discontinue the End User&rsquo;s rights to access the MyCanvass Platform at any time and at its sole discretion.</span></li>
                <li><span>Upon termination of this EULA for any reason:</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-disc ml-16">
                <li><span>All rights granted to the End User under this EULA shall cease; and</span></li>
                <li><span>The accrued rights of the parties at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination, shall not be affected or prejudiced.</span></li>
            </ol>
        </div>

        <div>
            <ol>
                <li className="text-center"><strong><u><span>10. Miscellaneous Provisions</span></u></strong><span>.</span></li>
            </ol>
        </div>
        <div>
            <ol className="list-decimal ml-8" start={1}>
                <li><span>If any provision of this EULA is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.</span></li>
                <li><span>This EULA, the MyCanvass Platform Access Policy, and the Independent Contractor Agreement constitute the entire agreement between the parties and supersede any previous arrangement, understanding, or agreement between them relating to the subject matter they cover.</span></li>
                <li><span>Each of the parties acknowledges and agrees that in entering into this EULA it does not rely on any undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not) of any person (whether party to this EULA or not) relating to the subject matter of this EULA, other than as expressly set out in this EULA.</span></li>
                <li><span>The End User shall not, without the prior written consent of MyCanvass, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this EULA.</span></li>
                <li><span>MyCanvass shall have no liability to the End User under this EULA if it is prevented from or delayed in performing its obligations under this EULA, or from carrying on its business by events which are outside of its control.</span></li>
                <li><span>The terms and conditions in the Independent Contractor Agreement shall prevail over the terms and conditions in this EULA to the extent of any conflict.</span></li>
                <li><span>This EULA and any disputes or claims arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by, and subject to the dispute resolution and choice of law provisions contained in, the Independent Contractor Agreement.</span></li>
            </ol>
        </div>

      </div>
    </>
  );
}
